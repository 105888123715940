import React, { ReactElement, useContext, useState } from 'react';
import jsPDF from 'jspdf';
import { without } from 'lodash';
import { PlayerLayerContext } from '../../../../shared/contexts/PlayerLayer.context';
import { PlayerList, PlayerSearchData } from '../../../../shared/services/openapi';
import { Toastify } from '../../../../shared/modules/Toastify/Toastify';
import { createSvg } from '../../short-list/ShortList';
import { generatePdf } from '../../../../shared/services/pdf-generation/pdf-generation-playerlist';
import LoadedPlayerListTemplate from './LoadedPlayerListTemplate';

const LoadedPlayerList = function({
    loadedList,
    players
}: {
    loadedList?: PlayerList,
    players: PlayerSearchData[]
}): ReactElement {

    const layerContext = useContext(PlayerLayerContext);

    const [loadings, setLoadings] = useState<string[]>([]);

    const saveCurrentListPdf = function(list: PlayerList, players: PlayerSearchData[]) {
        setLoadings(l => [...l, 'pdf']);
        generatePdf({
            list: players,
            charts: createSvg(players),
            additionalData: {
                label: list.ListName,
                date: list.ListDate,
                username: list.UserName
            }
        }).finally(
            () => setLoadings(l => without(l, 'pdf'))
        ).then(
            (pdf: jsPDF) => {
                pdf.save(`Playerlist_${list.ListName}.pdf`.replace(/\s/g, '_'));
            }
        ).catch(
            (error: Error) => {
                Toastify({
                    titleKey: 'general.message.error.pdf-generation.playerlist-failed'
                }).error();
                console.error(error);
            }
        );
    };

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true
        });
    };

    const searchToTeam = function(teamName: string) {
        layerContext.setContext({
            playerId: 0,
            isSearchModalOpen: teamName,
        });
    };

    return <>
        <LoadedPlayerListTemplate
            handlePlayerLayer={handlePlayerLayer}
            searchToTeam={searchToTeam}
            saveCurrentListPdf={saveCurrentListPdf}
            loadedList={loadedList}
            players={players}
            loadings={loadings}
        />
    </>;

};

export default LoadedPlayerList;
