import React, { ReactElement } from 'react';
import { EChartOption } from 'echarts';
import { noop } from 'lodash';
import EchartsWrapper from '../EchartsWrapper';
import { translate as t } from '../../../helpers/translate';
import { IMG } from '../../../helpers/utility';

interface ComponentProps<T> {
    showLabel?: boolean,
    dataList?: T,
    multiDataList?: (T | undefined)[],
    order: Array<keyof T>,
    config?: RadarConfig<T>,
    size?: { width: number, height: number },
    dotSize?: number,
    colors?: (string | undefined)[],
    labelColors?: (string | undefined)[],
    showTicks?: boolean,
    animation?: boolean,
    transparentArea?: boolean,
    createSvg?: (svg: SVGSVGElement | null | undefined) => void
}

export interface RadarConfig<T> {
    preKey?: string,
    modifiedValue?: (data: T, key: keyof T) => number,
}

const defaultColors = [
    '#FFD28F', //narancs
    '#4B9565', //zöld
    '#82CDFF' //kék
];

export const getChartOptions = function<T>({
    showLabel = true,
    dataList,
    multiDataList,
    order,
    config,
    dotSize = 8,
    colors,
    labelColors,
    showTicks = true,
    animation = true,
    transparentArea = true
}: ComponentProps<T>) {

    const allDataList = multiDataList ?? [dataList] ?? [];

    const lighten = function(hexa?: string): string {
        const [r, g, b] = IMG.hexToRgb(hexa ?? '#ffffff');
        return `rgba(${r}, ${g}, ${b}, 0.6)`;
    };

    return {
        radar: {
            shape: 'circle',
            indicator: [
                ...order.map(
                    key => ({
                        name: showTicks ? t((config?.preKey ?? 'general.chart.label.') + (key as string)) : '',
                        max: 100
                    })
                ),
            ],
            axisLabel: {
                show: false,
                rotate: 0,
                max: 3
            },
            axisNameGap: 20,
            axisName: {
                color: '#000000',
                fontWeight: 600
            },
        },
        series: [
            {
                type: 'radar',
                label: {
                    show: showLabel,
                    rotate: 0,
                    // color: '#000000'
                },
                labelLayout: {
                    // hideOverlap: true
                    // x: 150,
                    // moveOverlap: 'shiftY'
                },
                // labelLine: {
                //     show: true,
                //     length2: 5,
                //     lineStyle: {
                //         color: '#DDDDDD'
                //     }
                // },
                data: allDataList.filter(
                    data => !!data
                ).map(
                    (data, index) => ({
                        value: [
                            ...order.map(
                                key => config?.modifiedValue ? config.modifiedValue(data as T, key) : (data as T)[key] as number
                            )
                        ],
                        areaStyle: {
                            color: transparentArea
                                ? lighten(colors?.[index] ? colors[index] : defaultColors[index])
                                : (colors?.[index] ? colors[index] : defaultColors[index])
                        },
                        lineStyle: {
                            width: 4,
                            color: colors?.[index] ? colors[index] : defaultColors[index]
                        },
                        itemStyle: {
                            color: colors?.[index] ? colors[index] : defaultColors[index]
                        },
                        label: {
                            color: labelColors?.[index] ? labelColors[index] : '#000000'
                        },
                        symbolSize: dotSize
                    })
                )
            }
        ],
        animation
    };

};

const EchartsRadar = function<T>({
    showLabel = true,
    dataList,
    multiDataList,
    order,
    config,
    size,
    dotSize = 8,
    colors,
    labelColors,
    showTicks = true,
    animation = true,
    transparentArea = false,
    createSvg = noop
}: ComponentProps<T>): ReactElement {

    // const allDataList: (T | undefined)[] = useMemo(
    //     () => multiDataList ?? [dataList] ?? [],
    //     [dataList, multiDataList]
    // );

    const defaultOption: EChartOption<EChartOption.SeriesRadar> = getChartOptions({
        showLabel,
        dataList,
        multiDataList,
        order,
        config,
        dotSize,
        colors,
        labelColors,
        showTicks,
        animation,
        transparentArea
    });

    return <EchartsWrapper size={size} option={defaultOption} createSvg={createSvg}/>;

};

export default EchartsRadar;
