import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { without } from 'lodash';
import FootballPitch from '../../../shared/modules/FootballPitch/FootballPitch';
import { cx } from '../../../shared/helpers/utility';
import { translate as t } from '../../../shared/helpers/translate';
import GenericModal from '../../../shared/modules/GenericModal/GenericModal';
import { PositionModifier } from '../../../shared/modules/FootballPitch/PositionModifier/PositionModifier';
import {
    OwnTeamPlayerSearchData,
    PlayerBaseData, PlayerSearchData, SearchFieldService, SearchPlayer, ShadowTeam, ShadowTeamPlayers, ShadowTeam as ShadowTeamType
} from '../../../shared/services/openapi';
import { FradiLoader } from '../../../shared/modules/FradiLoader/FradiLoader';
import { ShadowTeamContext, ShadowTeamContextProps } from '../../../shared/contexts/ShadowTeam.context';
import { ShadowTeamSaveRequest } from './ShadowTeam';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import ConfirmModal from '../../../shared/modules/ConfirmModal/ConfirmModal';
import ShadowTeamSaves from './ShadowTeamSaves/ShadowTeamSaves';
import Tooltip from '../../../shared/modules/Tooltip/Tooltip';
import useAuth from '../../../shared/hooks/auth.hook';
import { toastError } from '../../../shared/helpers/errorhandler';
import { ShadowTeamEndpoints } from './endpoints';
import { OwnPositionModifier } from '../../../shared/modules/FootballPitch/OwnPositionModifier/OwnPositionModifier';
import css from './ShadowTeam.module.scss';

export interface SelectedPositionProps {
    position: string;
    players: PlayerBaseData[];
    isModalOpen?: boolean;
}

export interface ShadowTeamDescriptor {
    [positionId: string]: (PlayerSearchData | SearchPlayer)[];
}

export interface RawShadowTeamDescriptor {
    [positionId: string]: (PlayerSearchData | SearchPlayer | null)[];
}

export interface RawOwnShadowTeamDescriptor {
    [positionId: string]: (OwnTeamPlayerSearchData | SearchPlayer | null)[];
}

const ShadowTeamTemplate = function({
    own,
    loadings,
    setLoadings,
    isModalVisible,
    closeModal,
    saveShadowTeam,
    allPlayers,
    currentShadowTeam,
    setCurrentShadowTeam,
    loadedShadowTeam,
    setLoadedShadowTeam,
    setLoadedShadowTeamMetaData,
    savePdf,
    setEverythingSaved
}: {
    own: boolean,
    loadings: string[],
    setLoadings: React.Dispatch<React.SetStateAction<string[]>>,
    isModalVisible: boolean,
    closeModal: (changedState: boolean) => void,
    saveShadowTeam: (shadowTeamData: ShadowTeamSaveRequest) => Promise<ShadowTeamType | null | void>,
    allPlayers: PlayerSearchData[],
    currentShadowTeam: ShadowTeamDescriptor,
    setCurrentShadowTeam: React.Dispatch<React.SetStateAction<ShadowTeamDescriptor>>,
    loadedShadowTeam: ShadowTeamPlayers | undefined,
    setLoadedShadowTeam: React.Dispatch<React.SetStateAction<ShadowTeamPlayers | undefined>>,
    setLoadedShadowTeamMetaData: React.Dispatch<React.SetStateAction<ShadowTeam | undefined>>,
    savePdf: () => Promise<void>,
    setEverythingSaved: React.Dispatch<React.SetStateAction<boolean>>
}): ReactElement {

    const { requestParams } = useAuth();
    const shadowTeamContext = useContext(ShadowTeamContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [selectedPosition, setSelectedPosition] = useState<SelectedPositionProps>();
    const [rawShadowTeam, setRawShadowTeam] = useState<RawShadowTeamDescriptor | RawOwnShadowTeamDescriptor>({ });
    const [isFullScreen, setIsFullScreen] = useState<boolean>();
    const [isListModalVisible, setIsListModalVisible] = useState<boolean>(false);
    const [isSaveModalVisible, setIsSaveModalVisible] = useState<boolean>(false);
    const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState<boolean>(false);
    const [saveAsName, setSaveAsName] = useState<string>('');
    const [filterByPosition, setFilterByPosition] = useState<boolean>(false);

    useEffect(
        () => {
            resetShadowTeam();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [own]
    );

    function closeShortListModal(changedState: boolean) {
        setSelectedPosition(
            value => (value ? { ...value } : undefined)
        );
        closeModal(changedState);
    }

    function onPositionSelected(position: string) {
        setSelectedPosition({ position: position, players: [] });
        shadowTeamContext.setContext({
            ...shadowTeamContext.context as ShadowTeamContextProps,
            isModalOpen: true
        });
    }

    function setShadowPlayersForPosition(positionId: string, playerList: (PlayerSearchData | SearchPlayer | null)[]) {
        setRawShadowTeam(shadowTeam => ({
            ...shadowTeam,
            [positionId]: playerList
        }));
        setCurrentShadowTeam(shadowTeam => ({
            ...shadowTeam,
            [positionId]: playerList.filter(player => player !== null) as PlayerSearchData[]
        }));
        setEverythingSaved(false);
    }

    function preSaveShadowTeam(shadowTeam: ShadowTeamPlayers | null = null) {
        if (
            shadowTeamContext.context &&
            shadowTeamContext.context.selectedFormation &&
            Object.keys(currentShadowTeam).length > 0 &&
            Object.values(currentShadowTeam).some(playerList => playerList.length > 0)
        ) {
            if (inputRef.current && buttonRef.current) {
                inputRef.current.disabled = true;
                buttonRef.current.disabled = true;
            }
            let newShadowTeam: ShadowTeamType | null | void;
            setLoadings(l => [...l, 'save']);
            return saveShadowTeam({
                ShadowTeamID: shadowTeam?.ShadowTeamID,
                FormationID: shadowTeamContext.context.selectedFormation.FormationID,
                ShadowTeamName: shadowTeam ? shadowTeam.ShadowTeamName : saveAsName,
                Positions: Object.entries(currentShadowTeam).map(
                    ([positionId, player]) => ({
                        PositionID: positionId,
                        Players: player.map(
                            (playerData, index) => ({
                                PlayerID: playerData.PlayerID,
                                PlayerRank: index
                            })
                        )
                    })
                )
            }).finally(
                () => setLoadings(l => without(l, 'save'))
            ).then(
                (currentlySavedShadowTeam) => {
                    newShadowTeam = currentlySavedShadowTeam;
                    if (currentlySavedShadowTeam) {
                        return ShadowTeamEndpoints[own ? 'own' : 'glob'].load({
                            ...requestParams,
                            ShadowTeamID: currentlySavedShadowTeam.ShadowTeamID
                        });
                    }
                    else if (currentlySavedShadowTeam === null) {
                        Toastify({
                            titleKey: t('scouting.shadow.message.already-used')
                        }).warning();
                    }
                    else {
                        Toastify({
                            titleKey: t('scouting.shadow.message.shadow-team-save-failure')
                        }).error();
                    }
                }
            ).then(
                (shadowTeamPlayers) => {
                    if (newShadowTeam && shadowTeamPlayers) {
                        loadShadowTeam(newShadowTeam, shadowTeamPlayers);
                        setIsSaveAsModalVisible(false);
                        setSaveAsName('');
                        Toastify({
                            titleKey: t('scouting.shadow.message.save-success')
                        }).success();
                    }
                }
            ).catch(
                toastError
            );
        }
        else {
            Toastify({
                titleKey: t('scouting.shadow.message.no-shadow-team')
            }).warning();
            return Promise.resolve();
        }
    }

    async function loadShadowTeam(shadowTeam: ShadowTeam, shadowTeamPlayers: ShadowTeamPlayers) {
        setIsListModalVisible(false);
        setLoadedShadowTeamMetaData(shadowTeam);
        setLoadedShadowTeam(shadowTeamPlayers);
        shadowTeamContext.setContext({
            ...shadowTeamContext.context as ShadowTeamContextProps,
            selectedFormation: shadowTeamContext.context?.formations.find(
                formation => formation.FormationID === shadowTeamPlayers.FormationID
            )
        });
        const shadowTeamByPositions: Record<string, (PlayerSearchData | SearchPlayer)[]> = { };
        for (const position of shadowTeamPlayers.Positions) {
            shadowTeamByPositions[position.PositionID] = [];
            for (const player of position.Players) {
                const reportedPlayer = allPlayers.find(
                    playerData => playerData.PlayerID === player.PlayerID
                );
                if (!reportedPlayer) {
                    const notReportedPlayer = await searchPlayerById(player.PlayerID);
                    if (notReportedPlayer) {
                        shadowTeamByPositions[position.PositionID].push(notReportedPlayer);
                    }
                }
                else {
                    shadowTeamByPositions[position.PositionID].push(reportedPlayer);
                }
            }
        }
        // const shadowTeamByPositions = shadowTeamPlayers.Positions.reduce(
        //     (acc, curr) => ({
        //         ...acc,
        //         [curr.PositionID]: curr.Players.map(
        //             async (pl) => {
        //                 const reportedPlayer = allPlayers.find(
        //                     playerData => playerData.PlayerID === pl.PlayerID
        //                 );
        //                 if (!reportedPlayer) {
        //                     // TODO: keresni az összesben
        //                     const notReportedPlayer = await searchPlayerById(pl.PlayerID);
        //                     return notReportedPlayer;
        //                 }
        //                 else {
        //                     return reportedPlayer;
        //                 }
        //             }
        //         )
        //     }),
        //     { }
        // );
        setRawShadowTeam(shadowTeamByPositions);
        setCurrentShadowTeam(shadowTeamByPositions);
        setEverythingSaved(true);
    }

    function resetShadowTeam() {
        shadowTeamContext.setContext({
            ...shadowTeamContext.context as ShadowTeamContextProps,
            selectedFormation: undefined
        });
        setSelectedPosition(undefined);
        setRawShadowTeam({ });
        setCurrentShadowTeam({ });
        setLoadedShadowTeam(undefined);
        setLoadedShadowTeamMetaData(undefined);
        setEverythingSaved(true);
    }

    function searchPlayerById(id: number): Promise<SearchPlayer | void> {
        if (requestParams.UserID) {
            setLoadings(l => [...l, 'search']);
            return SearchFieldService.searchAllPlayersCreate({
                ...requestParams,
                PlayerIDs: [id],
                SeachField: ''
            }).finally(
                () => {
                    setLoadings(l => without(l, 'search'));
                }
            ).then(
                (player) => {
                    if (player.Players?.length === 1) {
                        return player.Players?.[0];
                    }
                    else {
                        Toastify({
                            titleKey: t('scouting.shadow.message.no-player-id')
                        }).warning();
                    }
                }
            ).catch(
                toastError
            );
        }
        else {
            return Promise.resolve();
        }
    }

    return <>
        <div className={cx(css.shadowTeamBox, isFullScreen ? css.fullScreen : null)}>
            <div className={css.buttons}>
                <button
                    className={cx(
                        'btn btn-secondary btn-outline',
                        css.filterPageButton,
                        loadings.includes('pdf') ? css.loading : null
                    )}
                    disabled={loadings.includes('pdf')}
                    onClick={savePdf}
                >
                    {t('scouting.shadow.buttons.export')}
                </button>
                <button
                    onClick={() => resetShadowTeam()}
                    className={cx('btn btn-danger btn-outline', css.filterPageButton)}
                >
                    {t('scouting.shadow.buttons.empty-shadow-team')}
                </button>
                <button
                    onClick={() => setIsListModalVisible(true)}
                    className={cx(
                        'btn btn-primary btn-outline',
                        css.filterPageButton,
                        loadings.includes('search') ? css.loading : null
                    )}
                >
                    {t('scouting.shadow.buttons.saved-shadow-teams')}
                </button>
                <button className={cx('btn btn-secondary btn-outline', css.dropdownToggle)}>
                    {t('scouting.shadow.buttons.save-shadow-team')}
                    <div className={css.dropdownMenu}>
                        <a
                            className={cx(css.dropdownItem, loadedShadowTeam ? '' : css.disabled)}
                            onClick={() => setIsSaveModalVisible(true)}
                        >
                            {t('scouting.shadow.buttons.save')}
                        </a>
                        <a
                            className={css.dropdownItem}
                            onClick={() => setIsSaveAsModalVisible(true)}
                        >
                            {t('scouting.shadow.buttons.save-as')}
                        </a>
                    </div>
                </button>
                <button
                    onClick={() => setFilterByPosition(current => !current)}
                    className={cx('btn btn-primary btn-outline', css.filterPageButton, filterByPosition && css.enabled)}
                    style={{ display: 'none' }}
                >
                    {t('scouting.shadow.buttons.filtered-shortlist')}
                </button>
                <Tooltip text={loadedShadowTeam?.ShadowTeamName} ifOverflow={true}>
                    <h3>{loadedShadowTeam?.ShadowTeamName}</h3>
                </Tooltip>
            </div>

            <FootballPitch
                selectPosition={onPositionSelected}
                currentShadowTeam={currentShadowTeam}
                isFullScreen={isFullScreen}
                toggleFullScreen={() => setIsFullScreen(state => !state)}
            />

            <GenericModal
                className='positionModifierBox'
                title={''}
                isVisible={isModalVisible}
                changeVisibility={closeShortListModal}
            >
                {selectedPosition && !own && (
                    <PositionModifier
                        positionId={selectedPosition?.position}
                        currentShadowTeam={rawShadowTeam as RawShadowTeamDescriptor}
                        setShadowPlayersForPosition={setShadowPlayersForPosition}
                        filterByPosition={filterByPosition}
                    />
                )}
                {selectedPosition && own && (
                    <OwnPositionModifier
                        positionId={selectedPosition?.position}
                        currentShadowTeam={rawShadowTeam as RawOwnShadowTeamDescriptor}
                        setShadowPlayersForPosition={setShadowPlayersForPosition}
                        filterByPosition={filterByPosition}
                    />
                )}
            </GenericModal>

            <GenericModal
                isVisible={isListModalVisible}
                title={'scouting.shadow.saved.list'}
                changeVisibility={() => setIsListModalVisible(false)}
            >
                <ShadowTeamSaves
                    own={own}
                    loadShadowTeamCallback={loadShadowTeam}
                />
            </GenericModal>

            <ConfirmModal
                isVisible={isSaveModalVisible}
                text={'scouting.shadow.saved.save'}
                changeVisibility={() => setIsSaveModalVisible(false)}
                callback={() => preSaveShadowTeam(loadedShadowTeam)}
            />

            <GenericModal
                className='saveAsBox'
                isVisible={isSaveAsModalVisible}
                title={'scouting.shadow.saved.save-as'}
                changeVisibility={() => setIsSaveAsModalVisible(false)}
            >
                <div className={css.saveAsContainer}>
                    <input
                        ref={inputRef}
                        className={css.saveAsInput}
                        type="text"
                        placeholder={t('scouting.shadow.saved.save-as-name')}
                        value={saveAsName}
                        maxLength={50}
                        onChange={event => setSaveAsName(event.target.value)}
                    />
                    <button
                        ref={buttonRef}
                        className={cx(css.saveAsButton, 'btn btn-primary')}
                        disabled={saveAsName.length === 0}
                        onClick={() => preSaveShadowTeam()}
                    >
                        {t('general.button.save')}
                    </button>
                </div>
            </GenericModal>

            <FradiLoader visible={loadings.includes('data')}/>
        </div>
    </>;

};

export default ShadowTeamTemplate;
