import React, { ReactElement } from 'react';
import SimpleBar from 'simplebar-react';
import { translate as t } from '../../../../shared/helpers/translate';
import { Html, cx } from '../../../../shared/helpers/utility';
import { PlayerList, PlayerSearchData } from '../../../../shared/services/openapi';
import PlayerShortListCard from '../../../../shared/modules/PlayerShortListCard/PlayerShortListCard';
import { FradiLoader } from '../../../../shared/modules/FradiLoader/FradiLoader';
import css from './LoadedPlayerList.module.scss';
import cssList from '../../short-list/ShortList.module.scss';
import ClipboardGrey from '../../../../assets/images/icons/icon_file_clipboard_grey.svg';

const LoadedPlayerListTemplate = function({
    handlePlayerLayer,
    searchToTeam,
    saveCurrentListPdf,
    loadedList,
    players,
    loadings
}: {
    handlePlayerLayer: (playerId: number) => void,
    searchToTeam: (teamName: string) => void,
    saveCurrentListPdf: (list: PlayerList, players: PlayerSearchData[]) => void,
    loadedList?: PlayerList,
    players: PlayerSearchData[],
    loadings: string[]
}): ReactElement {

    return <>
        <div className={cx('contentContainer', cssList.shortList)}>
            {loadedList && <>
                <div className={cx('contentHeader', cssList.contentHeader)}>
                    <h1>{t('scouting.saved-player-list.title')}</h1>
                    <div className="buttonsAndFilters">
                        <button
                            className={cx(
                                'btn btn-secondary btn-outline',
                                cssList.filterPageButton,
                                loadings.includes('pdf') ? cssList.loading : null
                            )}
                            disabled={loadings.includes('pdf')}
                            onClick={() => saveCurrentListPdf(loadedList, players)}
                        >
                            <img src={ClipboardGrey} alt=""/>
                            {t('scouting.saved-player-list.current-export')}
                        </button>
                    </div>
                </div>

                <h2 className={css.savedList}>
                    {loadedList.ListName}&nbsp;
                    ({t('scouting.saved-player-list.date')}: {loadedList.ListDate.split('T')[0]},&nbsp;
                    {t('scouting.saved-player-list.username')}: {loadedList.UserName})
                </h2>
                <div className={cssList.shortListContentModal}>
                    <div className={cx(cssList.shortListContainer, cssList.listed)}>
                        <p className={cssList.emptyStateText}><Html>{t('scouting.saved-player-list.empty-list-text')}</Html></p>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <table>
                                <thead>
                                    <tr className={cssList.listHeader}>
                                        <th className={cssList.playerPicture}></th>
                                        <th className={cssList.playerName}>{t('playerDetails.label.name')}</th>
                                        <th className={cssList.classification}>{t('playerDetails.label.classification')}</th>
                                        <th className={cssList.birthYear}>{t('playerDetails.label.info.Birthday')}</th>
                                        <th className={cssList.teamName}>{t('playerDetails.label.info.TeamName')}</th>
                                        <th className={cssList.post}>{t('playerDetails.label.post')}</th>
                                        <th className={cssList.minutes}>{t('playerDetails.label.minutes')}</th>
                                        <th className={cssList.expiration}>{t('playerDetails.label.ReportDate')}</th>
                                        <th className={cssList.fradiIndex}>{t('playerDetails.label.fradiIndex')}</th>
                                        <th className={cssList.polarChart}></th>
                                        <th className={cssList.shadowTeam}>{t('playerDetails.label.shadowTeam')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {players.map(player => (
                                        <PlayerShortListCard
                                            key={player.PlayerID}
                                            player={player}
                                            handlePlayerLayer={handlePlayerLayer}
                                            searchToTeam={searchToTeam}
                                            excludedColumns={['playerList', 'shortList']}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </div>
                </div>
            </>}
            <FradiLoader visible={!loadedList}/>
        </div>
    </>;

};

export default LoadedPlayerListTemplate;
