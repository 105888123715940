import React, { ChangeEvent, ReactElement, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { translate as t } from '../../../shared/helpers/translate';
import { FradiLoader } from '../../../shared/modules/FradiLoader/FradiLoader';
import { GeneralListType, SortingDescriptor } from './GeneralList';
import { Html, cx } from '../../../shared/helpers/utility';
import { LastNPlayerData, PlayerSearchData, PlayerSearchDataReport } from '../../../shared/services/openapi';
import PlayerShortListCard from '../../../shared/modules/PlayerShortListCard/PlayerShortListCard';
import PlayerReportListCard from '../../../shared/modules/PlayerReportListCard/PlayerReportListCard';
import GenericModal from '../../../shared/modules/GenericModal/GenericModal';
import { ProgressLine } from '../../../shared/modules/ProgressLine/ProgressLine';
import Select, { OptionType } from '../../../shared/modules/select/select';
import Tooltip from '../../../shared/modules/Tooltip/Tooltip';
import css from './GeneralList.module.scss';
import cssList from '../short-list/ShortList.module.scss';
import ClipboardGrey from '../../../assets/images/icons/icon_file_clipboard_grey.svg';

const GeneralListTemplate = function({
    type,
    players,
    reports,
    alerts,
    cubeAlerts,
    handlePlayerLayer,
    searchToTeam,
    downloadZip,
    progressValue,
    setProgressValue,
    optionPosition,
    selectedPosition,
    setSelectedPosition,
    optionCategory,
    selectedCategory,
    setSelectedCategory,
    searchTerm,
    changeSearchTerm,
    sorting,
    changeSorting,
    loadings
}: {
    type: GeneralListType,
    players: PlayerSearchData[],
    reports: PlayerSearchDataReport[],
    alerts: LastNPlayerData[],
    cubeAlerts: LastNPlayerData[],
    handlePlayerLayer: (playerId: number) => void,
    searchToTeam: (teamName: string) => void,
    downloadZip: (filename: string) => Promise<void>,
    progressValue: number,
    setProgressValue: React.Dispatch<React.SetStateAction<number>>,
    optionPosition: OptionType[],
    selectedPosition: number,
    setSelectedPosition: React.Dispatch<React.SetStateAction<number>>,
    optionCategory: OptionType[],
    selectedCategory: string,
    setSelectedCategory: React.Dispatch<React.SetStateAction<string>>,
    searchTerm: string,
    changeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void,
    sorting: SortingDescriptor,
    changeSorting: (column: keyof (PlayerSearchDataReport & PlayerSearchData)) => void,
    loadings: string[]
}): ReactElement {

    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState<boolean>(false);
    const [filename, setFilename] = useState<string>('');

    const download = function() {
        if (inputRef.current && buttonRef.current) {
            inputRef.current.disabled = true;
            buttonRef.current.disabled = true;
        }
        downloadZip(
            filename
        ).then(
            () => {
                setIsDownloadModalVisible(false);
            }
        );
    };

    const openPopup = function() {
        setFilename('');
        setProgressValue(0);
        setIsDownloadModalVisible(true);
    };

    const sortableColumn = function(
        column: keyof PlayerSearchDataReport, label: string, style: Record<string, string> = css
    ) {
        return (
            <Tooltip text={t(`general.label.sort.${label}`)}>
                <th
                    className={cx(
                        style[label], 'sortableColumn', sorting.column === column && 'sort-' + sorting.direction
                    )}
                    onClick={() => changeSorting(column)}
                >
                    {t(`playerDetails.label.${label}`)}
                </th>
            </Tooltip>
        );
    };

    return <>
        <div className={cx('contentContainer', cssList.shortList)}>
            <div className={cx('contentHeader', cssList.contentHeader)}>
                <h1>{t(`scouting.tabs.${type}`)}</h1>
                <div className="buttonsAndFilters">
                    <input
                        className={css.search}
                        type="search"
                        placeholder={t('scouting.search')}
                        value={searchTerm}
                        onChange={event => changeSearchTerm(event)}
                    />
                    <Select
                        isSoloLabel={true}
                        options={[
                            { id: '', value: t('scouting.short-list.all-categories') },
                            ...optionCategory
                        ]}
                        changeSelected={
                            (options) => setSelectedCategory(
                                String(options[0]?.id) ?? ''
                            )
                        }
                        selectedOptions={[{
                            id: selectedCategory,
                            value: selectedCategory === '' ? t('scouting.short-list.all-categories') : String(selectedCategory)
                        }]}
                        label={t('playerDetails.label.classification')}
                    ></Select>
                    <Select
                        isSoloLabel={true}
                        options={[
                            { id: 0, value: t('scouting.short-list.all-positions') },
                            ...optionPosition
                        ]}
                        changeSelected={
                            (options) => setSelectedPosition(
                                Number(options[0]?.id) || 0
                            )
                        }
                        selectedOptions={[{
                            id: selectedPosition,
                            value: selectedPosition === 0 ? t('scouting.short-list.all-positions') : String(selectedPosition)
                        }]}
                        label={t('playerDetails.label.post')}
                    ></Select>
                    {type === GeneralListType.reported && <>
                        <button
                            className={cx(
                                'btn btn-secondary btn-outline',
                                cssList.filterPageButton,
                                loadings.includes('download') ? cssList.loading : null
                            )}
                            disabled={loadings.includes('list')}
                            onClick={() => openPopup()}
                        >
                            <img src={ClipboardGrey} alt=""/>
                            {t('scouting.general-list.download-files')}
                        </button>
                    </>}
                </div>
            </div>
            <div className={cssList.shortListContent}>
                <div className={cx(cssList.shortListContainer, cssList.listed)}>
                    <p className={cssList.emptyStateText}><Html>{t('scouting.general-list.empty-list-text')}</Html></p>

                    {type === GeneralListType.followed && <>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <table>
                                <thead>
                                    <tr className={css.listHeader}>
                                        <th className={cssList.playerPicture}></th>
                                        {sortableColumn('PlayerName', 'playerName')}
                                        {sortableColumn('ClassificationID', 'classification')}
                                        {sortableColumn('Birthday', 'birthYear')}
                                        {sortableColumn('TeamName', 'teamName')}
                                        {sortableColumn('PlayerPositionID', 'post')}
                                        {sortableColumn('MinutesPlayed', 'minutes')}
                                        {sortableColumn('ReportDate', 'date')}
                                        {sortableColumn('ScoutingIndexGroupMean', 'fradiIndex')}
                                        <th className={cssList.polarChart}></th>
                                        {sortableColumn('InShadowTeamFlg', 'shadowTeam')}
                                    </tr>
                                </thead>
                                <tbody>
                                    {players.map(player => (
                                        <PlayerShortListCard
                                            key={player.PlayerID}
                                            player={player}
                                            handlePlayerLayer={handlePlayerLayer}
                                            searchToTeam={searchToTeam}
                                            excludedColumns={['playerList', 'shortList']}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            {/* <div className={cssList.list}>
                            </div> */}
                        </SimpleBar>
                    </>}

                    {type === GeneralListType.reported && <>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <table>
                                <thead>
                                    <tr className={css.listHeader}>
                                        <th className={css.playerPicture}></th>
                                        {sortableColumn('PlayerName', 'playerName')}
                                        {sortableColumn('ClassificationID', 'classification')}
                                        {sortableColumn('Birthday', 'birthYear')}
                                        {sortableColumn('TeamName', 'teamName')}
                                        {sortableColumn('ReportDate', 'date')}
                                        {sortableColumn('UserName', 'user')}
                                        {sortableColumn('MinutesPlayed', 'minutes')}
                                        {sortableColumn('PlayerPositionID', 'post')}
                                        {sortableColumn('ScoutingIndexGroupMean', 'fradiIndex')}
                                        <th className={css.preview}></th>
                                        <th className={css.shortList}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map(report => (
                                        <PlayerReportListCard
                                            key={report.ReportID}
                                            report={report}
                                            handlePlayerLayer={handlePlayerLayer}
                                            searchToTeam={searchToTeam}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </>}

                    {type === GeneralListType.alert && <>
                        <div>{JSON.stringify(alerts)}</div>
                    </>}

                    {type === GeneralListType.cubeAlert && <>
                        <div>{JSON.stringify(cubeAlerts)}</div>
                    </>}

                    <FradiLoader visible={loadings.includes('list')}/>
                </div>
            </div>
        </div>
        <GenericModal
            className='downloadBox'
            isVisible={isDownloadModalVisible}
            title={'scouting.general-list.download'}
            changeVisibility={() => setIsDownloadModalVisible(false)}
        >
            <div className={css.downloadContainer}>
                <fieldset>
                    <input
                        ref={inputRef}
                        className={css.downloadInput}
                        type="text"
                        placeholder={t('scouting.general-list.filename')}
                        value={filename}
                        maxLength={50}
                        onChange={event => setFilename(event.target.value)}
                    />
                    <span>.zip</span>
                </fieldset>
                <button
                    ref={buttonRef}
                    className={cx(css.downloadButton, 'btn btn-primary')}
                    disabled={filename.length === 0}
                    onClick={() => download()}
                >
                    {t('scouting.general-list.download')}
                </button>
                <ProgressLine value={progressValue}/>
            </div>
        </GenericModal>
    </>;

};

export default GeneralListTemplate;
