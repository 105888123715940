import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { noop, without } from 'lodash';
import {
    AdditionalService, PlayerBaseData, PlayerReport, PlayerReportService, Position, ProfileIndex, ReportParams
} from '../../../services/openapi';
import { cx, getCurrentAge, getYear } from '../../../helpers/utility';
import TabAccordion from '../../TabAccordion/TabAccordion';
import { translate as t } from '../../../helpers/translate';
import Select, { OptionType } from '../../select/select';
import { IndicatorValues, ProfileGroupContent, ProfileGroupTypes } from '../../../interfaces/carried-on.interfaces';
import { Rating } from '../../Rating/Rating';
import { CategorySelect } from '../../CaterorySelect/CategorySelect';
import { CategoryEnum, PLAYER_P_LIMIT, PLAYER_X_LIMIT, PlayerReportRequestType } from '../../../interfaces/reports.interface';
import { BooleanSelect } from '../../BooleanSelect/BooleanSelect';
import { PlayerLayerContext } from '../../../contexts/PlayerLayer.context';
import { ProgressLine } from '../../ProgressLine/ProgressLine';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import EchartsRadar from '../../EchartsWrapper/EchartsRadar/EchartsRadar';
import { Toastify } from '../../Toastify/Toastify';
import useAuth from '../../../hooks/auth.hook';
import { REPORT_COMMENT_MAX_LENGTH, indicatorOrder, nullPlayerReport } from '../../../constants';
import { toastError } from '../../../helpers/errorhandler';
import Tooltip, { BoxPoint, TooltipCollision } from '../../Tooltip/Tooltip';
import { MenuContext } from '../../../contexts/Menu.context';
import css from './ReportLayer.module.scss';
import CheckIcon from '../../../../assets/images/icons/icon_check_success.svg';
import SecondaryPositionsField from '../SecondaryPositionsField/SecondaryPositionsField';

const ReportLayer = function({
    reportData,
    playerBaseData,
    onCloseLayer,
    onSave,
    onNeedConfirmChange = noop,
    reportConfig,
}: {
    reportData: PlayerReport,
    playerBaseData: PlayerBaseData,
    onCloseLayer?: () => void,
    onSave: (reportData: PlayerReport) => void,
    onNeedConfirmChange?: (need: boolean) => void,
    reportConfig: ReportParams,
}): ReactElement {

    const defaultListContent = Object.values(ProfileGroupTypes).reduce(
        (obj, key) => ({ ...obj, [key]: [] }),
        { }
    ) as ProfileGroupContent;

    const { requestParams } = useAuth();
    const layerContext = useContext(PlayerLayerContext);
    const { setContext: setMenuReload } = useContext(MenuContext);

    const [loadings, setLoadings] = useState<string[]>([]);
    const [positionOptions, setPositionOptions] = useState<OptionType[]>([]);
    const [profileOptions, setProfileOptions] = useState<OptionType[]>([]);
    const [tabListContent, setTabListContent] = useState<ProfileGroupContent>(defaultListContent);
    const [selectedPositionId, setSelectedPositionId] = useState<number | null | undefined>(reportData.PlayerPositionID);
    const [teamReport, setTeamReport] = useState<PlayerReport>();
    const [reportSheetRequest, setReportSheetRequest] = useState<PlayerReportRequestType>(reportData);
    const [currentIndicatorTypes, setCurrentIndicatorTypes] = useState<string[]>([]);
    const [currentIndicatorGroup, setCurrentIndicatorGroup] = useState<ProfileGroupTypes>(ProfileGroupTypes.PHYSIQUE);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);
    const [isDirector, setIsDirector] = useState<boolean>(false);

    const allIndicator = getIndicatorTypeList(reportConfig?.ProfileIndex ?? []);
    const readOnly = reportData.ReadOnlyFlg ?? false;

    const progressValue = useMemo<number>(
        () => {
            const necessaryParams = [
                'ClassificationID',
                'PlayerPositionID',
                'PlayerProfileID',
                'ResellableFlg',
                'TypicalFormation'
            ];
            return currentIndicatorTypes.length
                ? Math.round(
                    [...currentIndicatorTypes, ...necessaryParams]
                        .map(key => reportSheetRequest[key as keyof PlayerReport])
                        .map<number>(value => value !== null ? 1 : 0)
                        .reduce((sum, inc) => sum + inc, 0)
                    / (currentIndicatorTypes.length + necessaryParams.length) * 100
                )
                : 0;
        },
        [currentIndicatorTypes, reportSheetRequest]
    );

    const getTeamAvg = useCallback(
        (positionId: number) => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'avg']);
                PlayerReportService.ownTeamPlayerAvgReportCreate({
                    ...requestParams,
                    PositionID: positionId
                }).finally(
                    () => {
                        setLoadings(l => without(l, 'avg'));
                    }
                ).then(
                    (teamAvgReport) => {
                        setTeamReport(
                            positionId ? { ...teamAvgReport.Positions[0].AvgStat, ReadOnlyFlg: false } : nullPlayerReport
                        );
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    useEffect(
        () => {
            setMenuReload(true);
        },
        [setMenuReload]
    );

    useEffect(
        () => {
            if (!reportConfig) {
                return;
            }
            setPositionOptions(
                reportConfig.PositionProfileMap?.map(
                    profileMap => profileMap.PositionID
                ).map(
                    positionId => ({
                        id: positionId,
                        value: String(positionId),
                    })
                ) ?? []
            );
        },
        [reportConfig]
    );

    useEffect(
        () => {
            setReportSheetRequest(sheet => {
                const allCalculated = {
                    ...sheet,
                    ...Object.values(ProfileGroupTypes).map(
                        group => reportSumMeanCalculate(sheet, group)
                    ).reduce(
                        (obj, item) => ({...obj, ...item}), {}
                    )
                };
                return {
                    ...allCalculated,
                    ...calculateScoutingIndexes(allCalculated)
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentIndicatorTypes]
    );

    useEffect(
        () => {
            const modified = (
                progressValue > 0 ||
                !!reportSheetRequest.Comment ||
                (reportSheetRequest.ClassificationID !== null && reportSheetRequest.ClassificationID !== '') ||
                reportSheetRequest.ResellableFlg !== null
            );
            setNeedConfirm(modified);
            onNeedConfirmChange(modified);
        },
        [
            onNeedConfirmChange,
            progressValue,
            reportSheetRequest.ClassificationID,
            reportSheetRequest.Comment,
            reportSheetRequest.PlayerPositionID,
            reportSheetRequest.ResellableFlg
        ]
    );

    useEffect(
        () => {
            getTeamAvg(Number(reportSheetRequest.PlayerPositionID));
        },
        [getTeamAvg, reportSheetRequest.PlayerPositionID]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                AdditionalService.checkPrivilegesCreate(
                    requestParams
                ).then(
                    (check) => {
                        setIsDirector(check.OwnShadowTeamFlg);
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    function searchToTeam(teamName: string) {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isSearchModalOpen: teamName
            });
        }
    }

    function calculateScoutingIndexes(allCalculated: {[p: string]: number | null} | PlayerReportRequestType) {
        const scoutingIndexSum = Object.values(ProfileGroupTypes)
            .map(key => allCalculated[key + 'Sum' as keyof PlayerReportRequestType] as number | null)
            .reduce<number>((sum, val) => sum + (val ?? 0), 0);
        const maxRateValue = 10;
        const allIndicatorPossibilitySum = (
            reportConfig.ProfileIndex?.find(
                i => i.PositionID === (reportData.PlayerPositionID ?? selectedPositionId)
            )?.ProfileIndexList?.flatMap(
                i => i.ProfileIndexIDList
            ).length ?? 0
        );
        const scoutingIndexMean = Math.round(scoutingIndexSum / (allIndicatorPossibilitySum * maxRateValue) * 100);
        return {ScoutingIndexGroupMean: scoutingIndexMean, ScoutingIndexGroupSum: scoutingIndexSum};
    }

    function onPositionChanged(selected: OptionType[]) {
        if (!selected.length) {
            return;
        }
        const positionId = +selected[0].id;
        setSelectedPositionId(positionId);
        setCurrentIndicatorTypes(getIndicatorTypeList(reportConfig?.ProfileIndex ?? [], positionId));
        setReportSheetRequest(sheet => {
            return sheet && {...sheet, PlayerPositionID: positionId};
        });
        const selectedPosition = reportConfig?.PositionProfileMap?.find(item => item.PositionID === positionId);
        if (selectedPosition && selectedPosition.ProfileIDList) {
            setProfileOptions(
                selectedPosition.ProfileIDList.map(
                    profile => profile.ProfileID
                ).map(
                    profileId => ({
                        id: profileId,
                        value: t('general.profile.' + profileId),
                    })
                )
            );
        }
        const collectionGroup = reportConfig?.ProfileIndex?.find(item => item.PositionID === selected[0].id);
        if (collectionGroup && collectionGroup.ProfileIndexList) {
            const collection: ProfileGroupContent = collectionGroup.ProfileIndexList.reduce((output, item) => ({
                ...output,
                [item.ProfileIndexGroupID as ProfileGroupTypes]: item.ProfileIndexIDList
            }), {} as ProfileGroupContent);
            setTabListContent(collection);
        }
    }

    function onProfileChanged(selected: OptionType[]) {
        if (!selected.length) {
            return;
        }
        setReportSheetRequest(sheet => {
            if (selected.length && selected[0].id) {
                return {
                    ...sheet,
                    PlayerProfileID: String(selected[0].id)
                };
            }
            return sheet;
        });
    }

    function onRateChanged(indicator: string, rate: number) {
        setReportSheetRequest(report => {
            const sheet = {...report, [indicator]: rate};
            const allCalculated = {...sheet, ...reportSumMeanCalculate(sheet)};
            return {...allCalculated, ...calculateScoutingIndexes(allCalculated)};
        });
    }

    function reportSumMeanCalculate(sheet: PlayerReportRequestType, indicatorGroup = currentIndicatorGroup) {
        const groupValueList = tabListContent[indicatorGroup].map(
            indicator => sheet[indicator.ProfileIndexID as keyof PlayerReportRequestType]
        );
        const sum = (
            groupValueList.filter(
                item => !!item
            ) as number[]
        ).reduce(
            (sum, item) => sum + item,
            0
        );
        // const mean  = Math.round((sum / (groupValueList.length * 10) * 100) * 2) / 2; // Round *2/2 => 0.5
        const mean  = Math.round((sum / (groupValueList.length * 10) * 100));
        return {
            // ...sheet,
            [indicatorGroup + 'Mean']: isNaN(mean) ? null : mean,
            [indicatorGroup + 'Sum']: isNaN(sum) ? null : sum,
        };
    }

    function getIndicatorTypeList(profileIndex: Array<Position>, positionId?: number): string[] {
        return reduceOrFilter(profileIndex, positionId)
            .map(item => item.ProfileIndexIDList ?? [])
            .reduce((a, b) => [...a, ...b], [])
            .map(item => item.ProfileIndexID)
            .filter((value, index, array) => array.indexOf(value) === index);
    }

    function reduceOrFilter(list: Array<Position>, positionId?: number): ProfileIndex[] {
        if (positionId) {
            return list.find(item => item.PositionID === positionId)?.ProfileIndexList ?? [];
        }
        return list
            .map(item => item.ProfileIndexList ?? [])
            .reduce((a, b) => [...a, ...b], []);
    }

    function saveReport() {
        if (currentIndicatorTypes.length > 0) {
            const excludeList = allIndicator.filter(indicator => currentIndicatorTypes.indexOf(indicator) < 0);
            onSave(
                Object.fromEntries(
                    Object.entries(reportSheetRequest).map(
                        ([key, value]) => [
                            key,
                            excludeList.indexOf(key) < 0 ? value : null
                        ]
                    )
                ) as PlayerReport
            );
        }
        else {
            Toastify({
                titleKey: 'general.message.error.report.empty'
            }).warning();
        }
    }

    function cancelReport() {
        if (needConfirm && !readOnly) {
            setIsCancelModalVisible(true);
        }
        else {
            onCloseLayer && onCloseLayer();
        }
    }

    function isPlayerCanBeX(): boolean {
        const age = getCurrentAge(playerBaseData.Birthday);
        return age <= PLAYER_X_LIMIT.max;
    }

    function isPlayerCanBeP(): boolean {
        const age = getCurrentAge(playerBaseData.Birthday);
        return age <= PLAYER_P_LIMIT.max;
    }

    function getDisabledCategories(): CategoryEnum[] {
        const disableds: CategoryEnum[] = [];
        if (!isPlayerCanBeX()) {
            disableds.push(CategoryEnum.x);
        }
        if (!isPlayerCanBeP()) {
            disableds.push(CategoryEnum.p);
        }
        return disableds;
    }

    return <>
        <div className={css.column}>
            <div className={cx(css.content, css.playerProfile)}>
                {playerBaseData && <div className={css.profile}>
                    <img
                        className={cx(
                            css.profileImg,
                            css['category-' + (Object.keys(CategoryEnum)[
                                Object.values(CategoryEnum).indexOf(reportData.ClassificationID as unknown as CategoryEnum)
                            ] ?? 'none')]
                        )}
                        src={playerBaseData.PlayerImageUrl}
                        alt=""
                    />
                    <div className={css.profileData}>
                        <div className={css.profileName}>
                            <div className={css.nameContiner}>
                                <span className={css.first}>{playerBaseData.PlayerName.split(' ')[0]}</span>
                                <span className={css.last}>{playerBaseData.PlayerName.split(' ').slice(1).join(' ')}</span>
                            </div>
                            <div className={cx(css.sector, css.sectorPoint)}>
                                <span className={css.label}>{t('playerDetails.label.index')}</span>
                                <div className={css.dottedCircle}>
                                    <div className={css.valueCircle}>
                                        <span className={css.indexValue}>{reportData?.ScoutingIndexGroupMean ?? '-'}</span>
                                    </div>
                                </div>
                            </div>
                            {isDirector && (
                                <div className={css.reporterContainer}>
                                    <p className={css.reporterText}>{t('playerDetails.report.table.reporter')}:</p>
                                    <p className={css.userName}>{reportData.UserName}</p>
                                </div>
                            )}
                        </div>
                        <div className={'separatorLine horizontal'}></div>
                        <div className={css.profileDetails}>
                            <div className={css.details}>
                                <span className={css.detailsLabel}>{t('playerReport.label.brnYear')}</span>
                                <span className={css.detailsValue}>{getYear(playerBaseData.Birthday)}</span>
                            </div>
                            <div className={css.details}>
                                <span className={css.detailsLabel}>{t('playerReport.label.team')}</span>
                                <span
                                    className={cx(css.detailsValue, css.clickable)}
                                    onClick={() => searchToTeam(playerBaseData.TeamName)}
                                >
                                    <img src={playerBaseData.TeamImageUrl} alt=""/>
                                    {playerBaseData.TeamName}
                                </span>
                            </div>
                            <div className={css.details}>
                                <span className={css.detailsLabel}>{t('playerReport.label.position')}</span>
                                <span className={css.detailsValue}>
                                    {reportData.PlayerPositionID ? reportData.PlayerPositionID : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className={css.sideBySide}>
                    <div className={css.selectors}>
                        <Select
                            isSoloLabel={true}
                            isLabelOnTop={true}
                            hideLabelWhenSelected={true}
                            changeSelected={(selectedOptions) => onPositionChanged(selectedOptions)}
                            options={positionOptions}
                            selectedOptions={positionOptions.filter(item => item.id === reportData.PlayerPositionID)}
                            runInitialChange={true}
                            label={'playerReport.label.position'}
                            disabled={readOnly}
                        />
                        <Select
                            isSoloLabel={true}
                            isLabelOnTop={true}
                            hideLabelWhenSelected={true}
                            changeSelected={(selectedOptions) => onProfileChanged(selectedOptions)}
                            options={profileOptions}
                            selectedOptions={profileOptions.filter(item => item.id === reportData.PlayerProfileID)}
                            runInitialChange={true}
                            label={'playerReport.label.profile'}
                            disabled={readOnly}
                        />
                        <div className={css.chartContainer}>
                            {!loadings.includes('avg') && (
                                <EchartsRadar<IndicatorValues>
                                    transparentArea={true}
                                    multiDataList={[
                                        teamReport || undefined,
                                        reportSheetRequest || undefined,
                                    ]}
                                    colors={[
                                        teamReport ? '#DDDDDD' : undefined,
                                        reportSheetRequest ? '#FFD28F' : undefined,
                                    ]}
                                    order={indicatorOrder}
                                />
                            )}
                        </div>
                    </div>
                    <SecondaryPositionsField
                        editing={true}
                        reportData={reportData}
                        readOnly={readOnly}
                        setReportSheet={setReportSheetRequest}
                    />
                </div>
            </div>
            <div className={cx(css.content, css.otherSettings)}>
                <div className={css.line}>
                    <span className={css.lineLabel}>{t('playerReport.label.category')}</span>
                    <CategorySelect
                        values={[reportSheetRequest.ClassificationID] as CategoryEnum[]}
                        onSelect={
                            (categories) => setReportSheetRequest(
                                (sheet) => ({...sheet, ClassificationID: categories[0]})
                            )
                        }
                        disabledValues={getDisabledCategories()}
                        disabled={readOnly}
                    />
                </div>
                <div className={css.line}>
                    <span className={css.lineLabel}>{t('playerReport.label.resellable')}</span>
                    <BooleanSelect
                        value={reportData.ResellableFlg ?? null}
                        onSelect={
                            (isResellable) => setReportSheetRequest(
                                (sheet) => ({
                                    ...sheet,
                                    ResellableFlg: isResellable
                                })
                            )
                        }
                        disabled={readOnly}
                        nullable={true}
                    />
                </div>
            </div>
        </div>
        <div className={css.column}>
            <div className={cx(css.content, css.fullFill)}>
                <TabAccordion<ProfileGroupTypes>
                    preTag={'general.group.'}
                    tabs={Object.values(ProfileGroupTypes)}
                    tabSelect={setCurrentIndicatorGroup}
                    defaultTab={ProfileGroupTypes.PHYSIQUE}
                >
                    {(selectedTab) => <>
                        <div className={css.indicatorBox}>
                            {reportData && tabListContent && tabListContent[selectedTab]
                                .map(item => item.ProfileIndexID)
                                .map(indicator => (
                                    <React.Fragment key={indicator}>
                                        <Tooltip
                                            text={t(`playerDetails.report.explanations.${indicator}`)}
                                            position={{
                                                my: BoxPoint['left top'],
                                                at: BoxPoint['left bottom'],
                                                of: 'toggler',
                                                within: window,
                                                offset: { x: 0, y: 5 },
                                                collision: TooltipCollision['shrink fit']
                                            }}
                                        >
                                            <div className={css.indicator}>
                                                {t(`playerDetails.report.explanations.${indicator}`) && (
                                                    <span className={css.info}>i</span>
                                                )}
                                                <Rating
                                                    label={'general.indicator.' + indicator}
                                                    value={
                                                        (reportSheetRequest[indicator as keyof PlayerReportRequestType]) as number | null
                                                    }
                                                    onRate={(rate) => onRateChanged(indicator, rate)}
                                                    disabled={readOnly}
                                                    className={isDirector ? 'greenBalls' : ''}
                                                />
                                            </div>
                                        </Tooltip>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        {!reportSheetRequest.PlayerPositionID && (
                            <div className={css.emptyContainer}>
                                <span>{t('playerReport.message.choosePosition')}</span>
                            </div>
                        )}
                    </>}
                </TabAccordion>
            </div>
            <div className={cx(css.content, css.comment)}>
                <span className={css.commentTitle}>{t('playerReport.label.comment')}</span>
                <div className={css.commentBox}>
                    <textarea
                        className={css.commentField}
                        rows={12}
                        value={reportSheetRequest.Comment ?? ''}
                        maxLength={REPORT_COMMENT_MAX_LENGTH}
                        onChange={(e) => setReportSheetRequest(sheet =>
                            ({...sheet, Comment: e.target.value})
                        )}
                        disabled={readOnly}
                    />
                    <div className={css.counter}>
                        <span>{reportSheetRequest.Comment?.length ?? 0}</span>
                        <span>/{REPORT_COMMENT_MAX_LENGTH}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className={css.row}>
            <div className={css.progressBox}>
                <span>{t('playerReport.label.progress')}</span>
                <ProgressLine value={progressValue}/>
            </div>

            <div className={css.actions}>
                <button className={cx('btn btn-primary btn-outline')} onClick={() => cancelReport()}>
                    {t('general.button.cancel')}
                </button>
                <button className={cx('btn btn-primary')} onClick={saveReport} disabled={readOnly}>
                    <img src={CheckIcon} alt=""/>{t('general.button.fixSave')}
                </button>
            </div>
        </div>
        <ConfirmModal
            isVisible={isCancelModalVisible}
            text={'general.message.without-save'}
            changeVisibility={() => setIsCancelModalVisible(false)}
            callback={() => Promise.resolve(onCloseLayer && onCloseLayer())}
        />
    </>;

};

export default ReportLayer;
